import {
  LottieInformation,
  SvgInformation,
} from "@bond-london/gatsby-graphcms-components";
import { LottieElement } from "@bond-london/gatsby-graphcms-components/dist/elements/LottieElement";

import classNames from "classnames";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { GraphCms_Position, GraphCms_Size } from "../generated/graphql-types";

interface Props {
  lottie?: LottieInformation;
  svg?: SvgInformation;
  image?: IGatsbyImageData;
  alt?: string;
  position: GraphCms_Position;
  size: GraphCms_Size;
}
const PositionedAsset: React.FC<Props> = ({ alt, lottie, position, size }) => {
  const topAdjust = "-translate-y-6/12";
  const bottomAdjust = "translate-y-6/12";

  return (
    <section
      data-component="Positioned Asset"
      className={classNames(
        "w-full h-0 relative z-0 pointer-events-none overflow-x-hidden"
      )}
    >
      <div
        className={classNames(
          "absolute",
          size === "Small" && "w-desktop-2-cols",
          size === "Medium" && "w-desktop-4-cols",
          size === "Large" && "w-desktop-6-cols",
          position === "TopLeft" && "left-0",
          position === "Left" && "left-0",
          position === "BottomLeft" && "left-0",
          position === "TopRight" && "right-0",
          position === "Right" && "right-0",
          position === "BottomRight" && "right-0",
          "transform-gpu",
          position === "Center" && "left-1/2 -translate-x-6/12",
          position === "TopLeft" && topAdjust,
          position === "TopRight" && topAdjust,
          position === "Left" && "",
          position === "Right" && "",
          position === "BottomLeft" && bottomAdjust,
          position === "BottomRight" && bottomAdjust
        )}
      >
        {lottie && (
          <LottieElement
            alt={alt || "Asset"}
            animationUrl={lottie.animationUrl}
            encoded={lottie.encoded}
            className="transform-gpu scale-150 -translate-y-6/12"
            loop={false}
          />
        )}
      </div>
    </section>
  );
};

export default PositionedAsset;
